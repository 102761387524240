import React, { useState } from 'react';
import anime from 'animejs/lib/anime.es.js';

import './TableOfContents2.css';


const TableOfContents = (props) => {
    
    const content = props.contents.tableOfContents;
    const handleHover = (index, isHovered) => {
        const el = document.querySelector(`.c${index}`)
        console.log(el.getBoundingClientRect())
        console.log(anime.get(el, 'left', 'px'))
        if(isHovered){
            anime({
                targets: `.c${index}`,
                filter: 'drop-shadow(0 0 4px #fff) drop-shadow(0 0 4px #FFF) drop-shadow(0 0 3px #0fa) drop-shadow(0px 0px 15px #0fa) drop-shadow(0px 0vw 20px #0fa)',
                duration:100,
                color:'#fff'
                // textShadow: '2px 2px 5px #0fa'
            })
        } else {
            anime({
                targets: `.c${index}`,
                filter: 'blur(0px)',
                textShadow: '0px 0px .2px #fff, 0px 0px .05em #0fa, 23px 24px 5px black, 0px 0px .2em #ccffcc',
                  color:'#0fa',
                duration:100
            })
        }
    };

    return (
        <div className='tableOfContents'>
            <div className='myNameContainer'>
                <h1 className={`myName`}>
                    {content.myName.split('').map((char, index) => (
                        <span key={index} className="letter">
                            {char}
                        </span>
                    ))}
                </h1>
            </div>
            <div className='workTitles'>
                {content.workTitle.map((title, index) => (
                    <h3
                        key={index}
                        className={`workTitle w${index}`}>
                        {title}
                    </h3>
                ))}
            </div>
            <div className='chapterList'>
                {content.selfTopics.map((topicObject, index) => (
                    console.log(topicObject),
                    <p
                        key={index}
                        className={`chapters c${index}`}
                        onMouseOver={() => handleHover(index, true)}
                        onMouseOut={() => handleHover(index, false)}
                        onClick={() => props.onTopicClick({ topicObject, index })}
                    >

                        {Object.values(topicObject)}

                    </p>
                ))}
            </div>
        </div>
    );
};

export default TableOfContents;