import './TextContent.css';

const PopUpWindow = ({ popUpData, open }) => {




    return (

        <div className='popup' >

            <h4 className='paragraph-sections'>Thanks {popUpData.name}!!!</h4>
            <p className='indented-paragraph'> 
                Your message was sent to us!  
            </p>
            <p className='indented-paragraph'> 
                Please look for a response in the next couple of days! 
            </p>


   
        
            <button className='closeModal' onClick={() => {
                // console.log(popUpData);
                popUpData =
                
                    // console.log('modal closed ');
                    open(open);
                }}  >
                    Close
                </button>




        </div>
    );
};

export default PopUpWindow ;