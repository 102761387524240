import anime from 'animejs/lib/anime.es.js';

const neonNameAnimation = () => 
    
  anime.timeline({
    
    targets: '.myName .letter',
    translateX: ['-23vw', 0],
    translateY: ['123vh', 0],
    scale: [5, 1],
    opacity: [0, 1],
    translateZ: 0,
    easing: 'easeInOutQuad',
    duration: 1000,
    delay: (el, i) => 200 + (130 * i),
    filter: ['blur(15px)', 'blur(0px)'],
  })
  .add({
    targets: '.myName .letter',
    color: ['#000', '#ccffcc'],
    textShadow: [
      'none',
      '0px 0px 2px #fff, 0px 0px .05em #0fa, 23px 24px 10px black, 0px 0px .2em #ccffcc',
    ],
    easing: 'easeInExpo',
    delay: anime.stagger(100),
  }, '+=500')
  .add({
    targets: '.myName .letter',
    delay: anime.stagger(110),
    duration: 1100,
    color: ['#ccffcc', '#0fa'],
    textShadow: [
      '0px 0px 2px #fff, 0px 0px .05em #0fa, 23px 24px 10px black, 0px 0px .2em #ccffcc',
      '0px 0px 1px #fff, 0px 0px .01em #0fa, 23px 24px 4px black, 0px 0px .1em #ccffcc',
    ],
    easing: 'easeInExpo',
  }, '-=100')
  .add({
    targets: '.workTitle', // Animate the workTitles array
    translateX: ['100vw', 0],
    delay: anime.stagger(150),
    duration: 500,
    // color: ['#ccffcc', '#0fa'],
    // easing: 'easeInOutSine',
    easing: 'spring(1, 80, 10, 0)',

  }, '-=2000')
  .add({
    targets: '.workTitle', // Animate the workTitles array
  
    delay: anime.stagger(150),
    duration: 500,
    color: ['#ccffcc', '#0fa'],

    // easing: 'easeInOutSine',
    textShadow: [
        'none',
        '0px 0px .2px #fff, 0px 0px .05em #0fa, 23px 24px 5px black, 0px 0px .2em #ccffcc',
      ],
  }, '-=1000')
  .add({
    targets: '.chapters',
    filter: ['blur(50px)', 'blur(0px)'],

    duration: 900,
    
  }, '-=2000')
  .add({
    targets: '.chapters',
    color: ['#000', '#0fa'],
    duration: 750,
    textShadow: [
        'none',
        '0px 0px .2px #fff, 0px 0px .05em #0fa, 23px 24px 5px black, 0px 0px .2em #ccffcc',
      ],
  }, '-=1500')

const pinkNameAnimation = () =>
  anime.timeline({
    targets: '.myName .letter',
    translateX: ['-23vw', 0],
    translateY: ['123vh', 0],
    scale: [4, 1],
    opacity: [0, 1],
    translateZ: 0,
    easing: 'easeInOutExpo',
    duration: 900,
    delay: (el, i) => 200 + (130 * i),
    filter: ['blur(15px)', 'blur(0px)'],
  })
  .add({
    targets: '.myName .letter',
    color: ['red', '#ff0000'],
    textShadow: [
      'none',
      '0px 0px 2px #fff, 0px 0px .05em #0fa, 23px 24px 10px black, 0px 0px .2em #ccffcc',
    ],
    easing: 'easeInExpo',
    delay: (el, i) => 200 + (80 * i),
  }, '+=500')
  .add({
    targets: '.myName .letter',
    delay: 1000,
    duration: 1500,
    color: ['black', '#0033cc'],
    textShadow: [
      '0px 0px 2px #fff, 0px 0px .05em #0fa, 23px 24px 10px black, 0px 0px .2em #ccffcc',
      '0px 0px 1px #fff, 0px 0px .01em #0fa, 23px 24px 4px black, 0px 0px .1em #ccffcc',
    ],
    easing: 'easeInExpo',
  }, '+=100');

export { neonNameAnimation, pinkNameAnimation };