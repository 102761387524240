import './App.css';
import { useState, useRef, useEffect, useCallback } from 'react';
import TableOfContents from './components/TableOfContents.js';
import Content from './content/Content.js';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import TopicWindow from './components/TopicWindow.js';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { neonNameAnimation, pinkNameAnimation } from './animations/neon';

const firebaseConfig = {
  apiKey: "AIzaSyDZODWdKBgN7mb6mUTSQ4jk38QHSpxzJ1w",
  authDomain: "newcontactform-2b27e.firebaseapp.com",
  databaseURL: "https://newcontactform-2b27e-default-rtdb.firebaseio.com",
  projectId: "newcontactform-2b27e",
  storageBucket: "newcontactform-2b27e.appspot.com",
  messagingSenderId: "537999855894",
  appId: "1:537999855894:web:8355dbd52ed387a3474037"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

const pageStyle = ['neon', 'pink'];
let index = 0;

function App() {
  const [parentData, setParentData] = useState('');

  const handleDataFromChild = useCallback((data) => {
    setParentData(data);
  }, []);
  console.log(parentData)
  const [visibleTopic, setVisibleTopic] = useState(null);
  const content = Content();
  const [isVisible, setIsVisible] = useState(false);
  const [pageTypeSelected, setPageTypeSelected] = useState('neon');


  const showTopicClicked = (props) => {
    console.log('jojojoj')
    setVisibleTopic(Object.keys(props.topicObject)[0])

  };

  const pageType = () => {
    index++;
    if (index > pageStyle.length - 1) {
      index = 0;
    }
    setPageTypeSelected(pageStyle[index])
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    const handleAnimation = () => {
      if (pageTypeSelected === 'neon') {
        neonNameAnimation();
      } else if (pageTypeSelected === 'pink') {
        pinkNameAnimation();
      }
    };
    handleAnimation();
  }, [pageTypeSelected]);

  return (
    <div className={`${pageTypeSelected} App `}>
      {/* <button onClick={pageType}>Change it up</button> */}


      <Header />
      <TableOfContents

        contents={content}
        onTopicClick={showTopicClicked}
        onDataChange={handleDataFromChild}

      />


      <Footer content={content.contact} visibleTopic={visibleTopic} />
      <TopicWindow info={content} visibleTopic={visibleTopic} setVisibleTopic={setVisibleTopic} />

    </div>

  );
}

export default App;